<template>
	<div>
		<div class='a-booking a-content a-faq'>
			<div class='app-container app-container-fluid a-container'>
				<div class='a-booking-container'>
					<div class='a-booking-content'>
						<div class='a-booking-box'>
							<p class='a-heading-1'>FAQ</p>

							<div class="row a-faq-search">
								<div class="col">
									<v-text-field
										:placeholder="`${$t('hc.search_question')}...`"
										icon-prepend='search'
										name='search'
										v-model='search'
										hide-details
									/>
								</div>
								<div class="col-auto">
									<div class='a-faq-search-button'>
										<v-button xxsmall color='green white--text'
											@click='updateSearch'
											:loading='loading_btn'
										>{{$t('hc.search')}}</v-button>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-6" v-for='(item, i) in items' :key='i'>
									<div class='a-faq-section'>
										<router-link :to='$i18nRoute({name: "faq-category", params: {categoryId: item[0].category_id}})' class='a-sub-heading'>{{item[0].category}}</router-link>

										<ul class='a-faq-list'>
											<li class='a-faq-list-item'
												v-for='(el, i) in item.slice(0, 4)'
												:key='i'
											>
												<router-link :to='$i18nRoute({name: "faq-single", params: {categoryId: el.category_id, id: el.id}})' class='a-faq-list-link'>{{el.question}}</router-link>
											</li>
										</ul>
										<template v-if='item.length > 4'>
											<router-link :to='$i18nRoute({name: "faq-category", params: {categoryId: i}})' class='app-link green--text'>{{$t('hc.show_all')}} ({{item.length}})</router-link>
										</template>
									</div>
								</div>
							</div>

							<div class="a-faq-section">
								<p class='a-heading-1 text-center' v-if='!loading && Object.keys(items).length === 0'>{{$t('hc.no_faq')}}</p>
							</div>

						</div>
					</div>
					<div class="a-booking-banner"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getFaqList } from '@/services/request';

	export default {
		data: () => ({
			search: '',
			items: [],
			loading: false,
			loading_btn: false
		}),
		created() {
			this.getFaqList();
		},
		methods: {
			getFaqList() {
				this.loading = true;
				getFaqList({
					search: this.search
				}).then(res => {
					this.items = this.$_.groupBy(res.data, 'category_id');
				}).finally(() => this.loading = false, this.loading_btn = false)
			},
			updateSearch() {
				this.loading_btn = true;
				this.getFaqList();
			}
		}
	}
</script>
